var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[(_vm.cargando)?_c('loading'):_vm._e(),_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"content-wrapper"},[_c('section',{staticClass:"content-header"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-sm-6"},[_c('ol',{staticClass:"breadcrumb float-sm-right"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":"/"}},[_vm._v("Home")])],1),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Administrativo")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("General")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v("Rutas")]),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Formulario "+_vm._s(_vm.accion)+" ")])])])])])]),_c('section',{staticClass:"content"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"col-6 pt-2 pb-2"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Formulario "+_vm._s(_vm.accion)+" Rutas")])]),_c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_vm._m(1),_c('li',{staticClass:"nav-item"},[(_vm.id != null)?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Sitios"}},[_vm._v("Sitios y Puntos de Gestión")]):_vm._e()]),_c('li',{staticClass:"nav-item"},[(
                    _vm.id != null &&
                      _vm.$store.getters.can('admin.rutas.edit')
                  )?_c('a',{staticClass:"nav-link",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Rutas"}},[_vm._v("Ruta")]):_vm._e()])]),_c('div',{staticClass:"tab-content",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade active show",attrs:{"id":"Principal"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-group col-md-4"},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre Ruta")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.nombre),expression:"form.nombre"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid',attrs:{"type":"text","maxlength":"100","id":"nombre"},domProps:{"value":(_vm.form.nombre)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "nombre", $event.target.value)}}})]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"codigo"}},[_vm._v("Código")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.codigo),expression:"form.codigo"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.codigo.$invalid
                              ? 'is-invalid'
                              : 'is-valid',attrs:{"type":"text","id":"codigo"},domProps:{"value":(_vm.form.codigo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "codigo", $event.target.value)}}})])]),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"origen"}},[_vm._v("Origen")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.origen_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Origen","label":"nombre","options":_vm.listasForms.origenes},on:{"input":function($event){return _vm.selectOrigen()}},model:{value:(_vm.origen),callback:function ($$v) {_vm.origen=$$v},expression:"origen"}})],1),_c('div',{staticClass:"form-group col-md-3"},[_c('label',{attrs:{"for":"destino"}},[_vm._v("Destino")]),_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.form.destino_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Destino","label":"nombre","options":_vm.listasForms.destinos},on:{"input":function($event){return _vm.selectDestino()}},model:{value:(_vm.destino),callback:function ($$v) {_vm.destino=$$v},expression:"destino"}})],1),_c('div',{staticClass:"form-group col-md-2"},[_c('label',{attrs:{"for":"horas_vigencia"}},[_vm._v("Horas de Vigencia")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.horas_vigencia),expression:"form.horas_vigencia"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.horas_vigencia.$invalid
                            ? 'is-invalid'
                            : 'is-valid',attrs:{"type":"number","id":"horas_vigencia"},domProps:{"value":(_vm.form.horas_vigencia)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "horas_vigencia", $event.target.value)}}})]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tipo_ruta"}},[_vm._v("Tipo de ruta")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_ruta),expression:"form.tipo_ruta"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_ruta.$invalid
                              ? 'is-invalid'
                              : 'is-valid',on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_ruta", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipoRutas),function(tipo_ruta){return _c('option',{key:tipo_ruta.numeracion,domProps:{"value":tipo_ruta.numeracion}},[_vm._v(" "+_vm._s(tipo_ruta.descripcion)+" ")])})],2)])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"tipo_despachado"}},[_vm._v("Tipo Despachado")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.tipo_despachado),expression:"form.tipo_despachado"}],staticClass:"form-control form-control-sm",class:_vm.$v.form.tipo_despachado.$invalid
                              ? 'is-invalid'
                              : 'is-valid',on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "tipo_despachado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.limpiarDespachado()}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_c('option',{attrs:{"value":"1"}},[_vm._v("ÚNICO DESPACHADO")]),_c('option',{attrs:{"value":"2"}},[_vm._v("MULTIDESPACHADO")])])])]),(_vm.form.tipo_despachado)?_c('div',{staticClass:"col-md-4"},[_c('label',{attrs:{"for":"fecha_expedicion"}},[_vm._v("DESPACHADO A: ")]),(_vm.form.tipo_despachado == 2)?_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                          _vm.$v.despachados.$invalid ? 'is-invalid' : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],attrs:{"placeholder":"Despachados","label":"descripcion","options":_vm.listasForms.despachados,"multiple":""},on:{"input":function($event){return _vm.selectDespachados()}},model:{value:(_vm.despachados),callback:function ($$v) {_vm.despachados=$$v},expression:"despachados"}}):_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.despachado_a_id),expression:"form.despachado_a_id"}],staticClass:"form-control form-control-sm",class:[
                          _vm.$v.form.despachado_a_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        ],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "despachado_a_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.despachados),function(despachado){return _c('option',{key:despachado.numeracion,domProps:{"value":despachado.numeracion}},[_vm._v(" "+_vm._s(despachado.descripcion)+" ")])})],2)],1):_vm._e()])])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Sitios"}},[_c('div',{staticClass:"card-body"},[(_vm.id != null)?_c('RutaSitio'):_vm._e()],1)]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"Rutas"}},[_c('div',{staticClass:"card-body"},[_c('RutaDetalle',{ref:"RutaDetalle"})],1)])]),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"row"},[_c('button',{staticClass:"btn bg-secondary col-md-1",on:{"click":function($event){return _vm.back()}}},[_c('i',{staticClass:"fas fa-reply"}),_c('br'),_vm._v("Volver ")]),(
                    _vm.$store.getters.can('admin.rutas.create') ||
                      _vm.$store.getters.can('admin.rutas.edit')
                  )?_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid),expression:"!$v.form.$invalid"}],staticClass:"btn bg-primary col-md-1",on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fas fa-paper-plane"}),_c('br'),_vm._v("Guardar ")]):_vm._e()])])])])])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-6"},[_c('h5',{staticClass:"mb-0"},[_vm._v("Rutas")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"tab-Principal","data-toggle":"tab","href":"#Principal"}},[_vm._v("Principal")])])
}]

export { render, staticRenderFns }